import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

const AutoLogout = ({ timeout }) => {
  const timeoutRef = useRef(null);
  const alertTimeoutRef = useRef(null);
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const logout = () => {
    clearTimeout(timeoutRef.current);
    clearTimeout(alertTimeoutRef.current);

    // Implement your logout logic here
    localStorage.clear();
    history.push('/'); // Redirect to login page
  };

  const showAlert = ({ open }) => {
    setIsModalVisible(open);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    resetTimeout(); // Reset the timer if the user opts not to be logged out
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    logout(); // Log out the user if they choose to log out
  };

  const resetTimeout = () => {
    clearTimeout(timeoutRef.current);
    clearTimeout(alertTimeoutRef.current);

    // Set the alert and logout timers
    alertTimeoutRef.current = setTimeout(() => showAlert({ open: true }), timeout * 0.7);
    timeoutRef.current = setTimeout(logout, timeout);
  };

  const handleActivity = () => {
    resetTimeout();
  };

  useEffect(() => {
    // Set initial timeouts
    alertTimeoutRef.current = setTimeout(() => showAlert({ open: true }), timeout * 0.7);
    timeoutRef.current = setTimeout(logout, timeout);

    // Listen for user activity
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);
    window.addEventListener('scroll', handleActivity);
    window.addEventListener('click', handleActivity);

    // Clean up event listeners and timeouts on component unmount
    return () => {
      clearTimeout(timeoutRef.current);
      clearTimeout(alertTimeoutRef.current);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      window.removeEventListener('scroll', handleActivity);
      window.removeEventListener('click', handleActivity);
    };
  }, [timeout]);

  return (
    <Modal
      title="About to log you out"
      icon={<ExclamationCircleOutlined />}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Don't Log me Out"
      cancelText="Log me Out"
    >
      You have been inactive for some time. The system is about to log you out for security reasons.
    </Modal>
  );
};

export default AutoLogout;
