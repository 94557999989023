import { Button, Modal, Form, Input, Select, Row, Col, DatePicker, TimePicker, List } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { requestError } from '../../controllers/functions';
const { TextArea } = Input;
const App = ({show, setOpenAccessLevelModal, action, accessLevelId, refresh }) => {
  useEffect(() => {
    if(action == `create`){
      clear();
    }
    axios.get(process.env.REACT_APP_API_URI+"/auth/permissions/", {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
      setPermissions(data.data.res);
    }).then(next=>{

        if(action == `edit`){
          axios.get(process.env.REACT_APP_API_URI+`/auth/accesslevels/${accessLevelId}/permissionsids`, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
            setName(data.data.accessLevel?.name)
            setPermissionIds(data.data.permissions)
          }).catch(error =>{
            console.log(error)
          })
        }

    }).catch(error =>{
      console.log(error)
    })

  }, [show]);

  const clear = () =>{
    setName("");
    setPermissionIds([]);
  }

  const [isModalVisible, setIsModalVisible] = useState(false);
  if(show != isModalVisible){
    setIsModalVisible(show)
  }

  const { Option } = Select;
  const [permissions, setPermissions] = useState([])
  const [name, setName] = useState("")
  const [permissionIds, setPermissionIds] = useState([])


  const submit = (event) =>{
    const data = {
      name : name,
      permissions : permissionIds
    } 
    axios.post(process.env.REACT_APP_API_URI+"/auth/accesslevelwithpermissions/", data , {headers: { authorization : `Beare ${localStorage.getItem("token")}`}} ).then(res=>{
      setOpenAccessLevelModal(false);
      refresh();
    }).catch(error=>{
        requestError({error});
    }).finally(done =>{
      setLoading(false)
    })
  }

  const edit = (event) =>{
    var data = [];
    permissionIds.forEach(element => {
      data.push({
        accessLevelId : accessLevelId,
        permissionId : element
      } )
    });
    axios.put(process.env.REACT_APP_API_URI+"/auth/accesslevelwithpermissions/", {name : name, permissions : data} , {headers: { authorization : `Beare ${localStorage.getItem("token")}`}} ).then(res=>{
      setOpenAccessLevelModal(false);
      refresh();
    }).catch(error=>{
      requestError({error});
    }).finally(done =>{
      setLoading(false)
    })
  }

  const [loading, setLoading] = useState(false)

  return (
    <>
    <Form
        layout="vertical"
        className="row-col"
      >
      <Modal 
      title="ACCESS LEVEL" 
      width={"100%"}
      visible={isModalVisible} 
      onOk={()=>setOpenAccessLevelModal(false)} 
      onCancel={()=>setOpenAccessLevelModal(false)}
      footer={[
        <Button key="submit" type=""  onClick={()=>{setOpenAccessLevelModal(false)}}>
            Cancel
        </Button>,
        action == `create`?
        <Button key="submit" type="primary" loading={loading} onClick={()=>{submit();setLoading(true)}}>
            Submit
        </Button>
        :
        <Button key="submit" type="primary" loading={loading} onClick={()=>{edit();setLoading(true)}}>
            Save
        </Button>
      ]}
      >
        <Form.Item>
          <Input value={name} onChange={(event)=>setName(event.target.value)} name = "name" placeholder="Type Access Level... " />
        </Form.Item>
        <Form.Item>
        <Select className='m-0'
          mode="multiple"
          showSearch
          value={permissionIds}
          onChange={(event)=>{
            setPermissionIds(event);
          }}
          placeholder="Shift Worker..."
          optionFilterProp="children"
          dropdownStyle={{
            width : "50%"
          }}
      
        >
          {
            permissions?.map(item =>
              <Option  key={item.id} value={item.id}>{item.name}</Option>
              )
          }
        </Select>
        </Form.Item>
        
      </Modal>
      </Form>
    </>
  );
};

export default App;