

import { Button, Modal, Input, Form, Select, message, Col, Row, DatePicker,  } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { requestError } from '../../controllers/functions';
import DatePickerComponent from '../date/period';
const { Option } = Select;
const { RangePicker } = DatePicker;
const SchoolPageFeesModal = ({ show, setOpenModal, record, action, refresh }) => {

  const [form] = Form.useForm();
  
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [state, setState] = useState({});
  const [terms, setTerms] = useState([]);
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    setState({...record});
    form.resetFields();
    form.setFieldsValue({...record});

    
    axios.get( process.env.REACT_APP_API_URI+"/school/terms", {headers: { authorization : `Bearer ${localStorage.getItem("token")}`}} ).then( done =>{
        setTerms(done.data);
    }).catch( error => {
        requestError({error});
    })
    
    axios.get( process.env.REACT_APP_API_URI+"/school/fees/currencies", {headers: { authorization : `Bearer ${localStorage.getItem("token")}`}} ).then( done =>{
      setCurrencies(done.data);
    }).catch( error => {
        requestError({error});
    })


  }, [show]);

  if(show !== isModalVisible){
    setIsModalVisible(show);
  }

  const submit = (v) =>{

    axios.post(process.env.REACT_APP_API_URI+"/school/fees", v , {headers: { authorization : `Bearer ${localStorage.getItem("token")}`}} ).then(res=>{
        message.success(res.data.res)
        setOpenModal(false);
        refresh();
      }).catch(error=>{
        requestError({error});
      }).finally(done=>{
          setLoading(false);
      })


    
  }

  const edit = (v) =>{
    
    axios.put(process.env.REACT_APP_API_URI+`/school/fees/${state?.id}`, v , {headers: { authorization : `Bearer ${localStorage.getItem("token")}`}} ).then(res=>{
        message.success(res.data.res)
        setOpenModal(false);
        refresh();
      }).catch(error=>{
        requestError({error});
      }).finally(done=>{
          setLoading(false);
      })

  }

  const [loading, setLoading] = useState(false)

  

  return (
    <>
    
      <Modal 
      title={` SCHOOL FEES DETAILS ${action !== `create`?`FOR #${state?.name}`: ``}` }
      visible={isModalVisible} 
      onOk={()=>setOpenModal(false)} 
      onCancel={()=>setOpenModal(false)}
      footer={[
        <Button key="cancel" type=""  onClick={()=>{setOpenModal(false)}}>
            Cancel
        </Button>,
        <Button  key="submit" type="primary" htmlType="submit" form="schoolFeesForm" loading={loading}>
          {action === `create`? `Submit` : `Update`}
        </Button>     
      ]}
      >
      <Form
      layout="vertical"
      className="row-col"
      onFinish={(v)=> {
        setLoading(true);
        if(action === "edit") {
          edit(v);
        }else{
          submit(v);
        }
      }}
      id="schoolFeesForm" 
      form={form}
      >

        <Row>

          <Col xs={24} sm={24} md={17} lg={17} >
            <Form.Item
            name = "schoolTermId" 
            style={{ marginRight: 5 }}
            rules={[
                { 
                  required: true, 
                  message: "Please input your  Term!" 
                },
            ]}>
              <Select
                showSearch
                onChange={(value)=>setState({ ...state, schoolTermId : value })}
                value={state?.schoolTermId}
                placeholder="Select Term"
                optionFilterProp="children"
                filterOption={(input, option) => option.children.includes(input)}
                >
                    {
                        terms.map(item =>
                        <Option value={item.id} key={item.id}>{item.name}</Option>
                        )
                    }
                </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={7} lg={7} >
                <Form.Item
                style={{marginRight: 5}}
                name={'amount'}
                >
                    <Input style={{ height : 33 }} name = "amount" type='number' placeholder = "Amount" /> 
                </Form.Item>
            </Col>

        </Row>

        </Form>
      </Modal>
    </>
  );
};

export default SchoolPageFeesModal;