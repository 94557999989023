

import { Button, Modal, Input, Form, Select, message, Col, Row, DatePicker,  } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { requestError } from '../../controllers/functions';
import DatePickerComponent from '../date/period';
const { Option } = Select;
const { RangePicker } = DatePicker;
const SchoolBaseCurrencyModal = ({ show, setOpenModal }) => {

  const [form] = Form.useForm();
  
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [state, setState] = useState({});

  const [currencies, setCurrencies] = useState([]);



  useEffect(() => {
    form.resetFields();
    axios.get( process.env.REACT_APP_API_URI+"/school/fees/currencies", {headers: { authorization : `Bearer ${localStorage.getItem("token")}`}} ).then( done =>{
        setCurrencies(done.data);
      }).catch( error => {
          requestError({error});
      })


  }, [show]);

  if(show !== isModalVisible){
    setIsModalVisible(show);
  }

  const submit = (v) =>{

    
    axios.post(process.env.REACT_APP_API_URI+"/currency/settings/school/base-currency", v , {headers: { authorization : `Bearer ${localStorage.getItem("token")}`}} ).then(res=>{
        message.success(res.data.res)
        setOpenModal(false);
      }).catch(error=>{
        requestError({error});
      }).finally(done=>{
          setLoading(false);
      })

    
  }



  const [loading, setLoading] = useState(false)

  

  return (
    <>
    
      <Modal 
      title={`SCHOOL BASE CURRENCY` }
      visible={isModalVisible} 
      onOk={()=>setOpenModal(false)} 
      onCancel={()=>setOpenModal(false)}
      footer={[
        <Button key="cancel" type=""  onClick={()=>{setOpenModal(false)}}>
            Cancel
        </Button>,
        <Button  key="submit" type="primary" htmlType="submit" form="schoolBaseCurrencyForm" loading={loading}>
          Submit
        </Button>     
      ]}
      >
      <Form
      layout="vertical"
      className="row-col"
      onFinish={(v)=> {
        setLoading(true);
        submit(v);
      }}
      id="schoolBaseCurrencyForm" 
      form={form}
      >

        <Row>

          <Col xs={24} sm={24} md={20} lg={20} >
          <Form.Item
                style={{marginRight: 5}}
                name={'baseCurrencyId'}
                >
                    <Select
                    showSearch
                    onChange={(value)=>setState({ ...state, baseCurrencyId : value })}
                    value={state?.baseCurrencyId}
                    placeholder="Select School Currency"
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.includes(input)}
                    >
                        {
                            currencies.map(item =>
                            <Option value={item.id} key={item.id}>{item.name}</Option>
                            )
                        }
                    </Select>
                </Form.Item>
          </Col>

        </Row>

        

        </Form>
      </Modal>
    </>
  );
};

export default SchoolBaseCurrencyModal;