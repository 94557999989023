
import {message } from 'antd'


const requestError = ({error}) => {
  if(error?.response?.status === 401 ) {
    
    window.location = `/?url=${window.location.href}`

  } else if(error?.response?.status === 402 ) {
    
    window.location = `/subscribe`

  }else{
    message.error(error.response?.data?.res || error.response?.data || error?.message || "Server application not available");
  }
}

export {requestError};

const  getQueryParams = () => {
  const params = new URLSearchParams(window.location.search);
  const queryParams = {};
  for (const [key, value] of params.entries()) {
      queryParams[key] = value;
  }
  return queryParams;
}

export{getQueryParams}