

import { Button, Modal, Input, Form, Select, message, Col, Row, DatePicker,  } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { requestError } from '../../controllers/functions';
import DatePickerComponent from '../date/period';
const { Option } = Select;
const { RangePicker } = DatePicker;
const SchoolPagePublicHolidayModal = ({ show, setOpenModal, record, action, refresh }) => {

  const [form] = Form.useForm();
  
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [state, setState] = useState({});


  useEffect(() => {
    setState({...record});
    form.resetFields();
    form.setFieldsValue({...record})
  }, [show]);

  if(show !== isModalVisible){
    setIsModalVisible(show);
  }

  const submit = (v) =>{

    
    axios.post(process.env.REACT_APP_API_URI+"/public/holidays", v , {headers: { authorization : `Bearer ${localStorage.getItem("token")}`}} ).then(res=>{
        message.success(res.data.res)
        setOpenModal(false);
        refresh();
      }).catch(error=>{
        requestError({error});
      }).finally(done=>{
          setLoading(false);
      })

    
  }

  const edit = (v) =>{

    axios.put(process.env.REACT_APP_API_URI+`/public/holidays/${state?.id}`, v , {headers: { authorization : `Bearer ${localStorage.getItem("token")}`}} ).then(res=>{
        message.success(res.data.res)
        setOpenModal(false);
        refresh();
      }).catch(error=>{
        requestError({error});
      }).finally(done=>{
          setLoading(false);
      })
  }

  const [loading, setLoading] = useState(false)

  

  return (
    <>
    
      <Modal 
      title={`PUBLIC HOLIDAY DETAILS ${action !== `create`?`FOR #${state?.id}`: ``}` }
      visible={isModalVisible} 
      onOk={()=>setOpenModal(false)} 
      onCancel={()=>setOpenModal(false)}
      footer={[
        <Button key="cancel" type=""  onClick={()=>{setOpenModal(false)}}>
            Cancel
        </Button>,
        <Button  key="submit" type="primary" htmlType="submit" form="publicHolidayForm" loading={loading}>
          {action === `create`? `Submit` : `Update`}
        </Button>     
      ]}
      >
      <Form
      layout="vertical"
      className="row-col"
      onFinish={(v)=> {
        setLoading(true);
        if(action === "edit") {
          edit(v);
        }else{
          submit(v);
        }
      }}
      id="publicHolidayForm" 
      form={form}
      >

        <Row>

          <Col xs={24} sm={24} md={14} lg={14} >
            <Form.Item
            name = "name" 
            style={{ marginRight: 5 }}
            rules={[
                { 
                  required: true, 
                  message: "Please enter Name!" 
                },
            ]}>
              <Input style={{ height : 33 }}  name='name' placeholder='Enter your event name'/>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={10} lg={10} >
                <Form.Item
                style={{marginRight: 5}}
                name={"date"}
                >
                    <DatePicker style={{ width :"100%"}} name={'date'}/>
                </Form.Item>
            </Col>

        </Row>

        

        </Form>
      </Modal>
    </>
  );
};

export default SchoolPagePublicHolidayModal;