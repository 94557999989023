import { useEffect, useState } from "react";
import { requestError } from "../../controllers/functions";
import axios from "axios"
import moment from "moment"
import dayjs from "dayjs"
import { EditFilled } from "@ant-design/icons";
import SchoolPageTermModal from "../modals/school.page.terms";
import SchoolPageEventModal from "../modals/school.page.event";
import SchoolPagePublicHolidayModal from "../modals/school.page.public.holidays";

const { List, Button, Typography, Form, Modal } = require("antd")


const PublicHolidaysComponent = ({ permissions }) => {

    const [form] = Form.useForm();
    const [record, setRecord] = useState(null);
    const [openRecordModal, setOpenRecordModal] = useState(false);
    const [action, setAction] = useState('create');


    
    const [ state, setState ] = useState({ updateSchoolDetails : false });

    useEffect ( () => {
        refresh()
    }, [] ) 

    const refresh = () => {

        axios.get( process.env.REACT_APP_API_URI+"/public/holidays", {headers: { authorization : `Bearer ${localStorage.getItem("token")}`}} ).then( done =>{
            setState({ ...state, terms : done.data });
            form.setFieldsValue(done.data);
        }).catch( error => {
            requestError({error});
        })

    }

    const deleteRecord = (id) => {

        axios.delete( process.env.REACT_APP_API_URI+`/public/holidays/${id}`, {headers: { authorization : `Bearer ${localStorage.getItem("token")}`}} ).then( done =>{
            refresh();
        }).catch( error => {
            requestError({error});
        })

    }

    return ( 
        <>
            <List
                dataSource={ state?.terms || [] }
                renderItem={(item) => (
                    <List.Item

                        actions={[
                            <Button 
                            onClick={()=>{
                                Modal.confirm({
                                title  : "Are you want to delete this?",
                                onCancel : ()=>{},
                                onOk :  ()=> deleteRecord(item?.id)
                              })}} 
                            style={{ height : 33, lineHeight :0 }} danger> Delete </Button>,
                            
                            <Button onClick={()=>{
                                setAction("edit");
                                setRecord(item);
                                setOpenRecordModal(true);
                            }} style={{ height : 33, lineHeight :0 }}> <EditFilled/> Edit </Button>
                        ]}
                    >
                        {
                            `
                                ${item?.date && moment(item?.date).format("DD/MM/YYYY")}
                            `
                        } 
                        {item?.name}
                    </List.Item>
                )}
            />

            <Button onClick={()=>{
                setOpenRecordModal(true);
                setRecord({});
                setAction("create");
            }} style={{ height : 33, lineHeight :0, float : "right", marginTop : "1rem" }}
            type="primary"
            >Add Public Holiday
            </Button>

            <SchoolPagePublicHolidayModal 
                refresh={refresh} 
                record={{
                    ...record,
                    date : record?.date && dayjs(record?.date)
                }} 
                action={action} 
                setOpenModal = {setOpenRecordModal} 
                show = {openRecordModal} 
            />

        </>
    )
}


export {PublicHolidaysComponent}