import {DatePicker} from 'antd'
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import moment from "moment"
const { RangePicker } = DatePicker;


const DatePickerComponent = ({reportPeriod, setReportPeriod, style }) => {

    const dateFormat = 'DD/MM/YYYY';

    return (
        <RangePicker
            style={{ 
                ...style
            }}
            allowClear={false}
            value={[
                dayjs(moment(reportPeriod[0]).format("DD/MM/YYYY"), dateFormat ), 
                dayjs(moment(reportPeriod[1]).format("DD/MM/YYYY"), dateFormat)
            ]}
            onChange={ (value) => {
                setReportPeriod([
                    moment( dayjs(value[0]).format(dateFormat), dateFormat ),
                    moment( dayjs(value[1]).format(dateFormat), dateFormat ),
                ])
            }} 
        />
    )
}

export default DatePickerComponent;