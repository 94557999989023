import axios from "axios"
import SchoolBaseCurrencyModal from "../modals/school.page.base,currency";
import { Link } from "react-router-dom";

const { EditOutlined, CloseCircleOutlined } = require("@ant-design/icons");
const { Card, Button, Form, Input, Select, message } = require("antd")
const { default: FormItem } = require("antd/es/form/FormItem");

const { useEffect, useState } = require("react");
const { requestError } = require("../../controllers/functions");

const { Option } = Select;


const SchoolDetailsComponent = ( {permissions } ) => {

    const [form] = Form.useForm();
    const [openCurrencyModal, setOpenCurrencyModal] = useState(false);


    
    const [ state, setState ] = useState({ updateSchoolDetails : false });

    useEffect ( () => {
        refresh();
    }, [] ) 

    const refresh = () => {
        axios.get( process.env.REACT_APP_API_URI+"/schools/our/details", {headers: { authorization : `Bearer ${localStorage.getItem("token")}`}} ).then( done =>{
            setState({ ...state, school : done.data });
            form.setFieldsValue(done.data);
        }).catch( error => {
            requestError({error})
        })
    }
    const edit = (values) =>{

        setState({ ...state, savingSchoolDetails : true });

        axios.put(process.env.REACT_APP_API_URI+`/schools`, values , {headers: { authorization : `Bear ${localStorage.getItem("token")}`}} ).then(res=>{
            message.success(res.data.res);
            setState(prev => { return { ...prev, updateSchoolDetails :!prev?.updateSchoolDetails  }});
            refresh();
        }).catch(error=>{
          requestError({error});
        }).finally( done =>{
          setState({ ...state, savingSchoolDetails : false })
        })
      }

    return (
        <>
        <Card
                title={"Our School"}
                size="small"
                className="school-page-widget-card"
                extra = {
                    <>

                        { 
                            state?.updateSchoolDetails && <Button 
                            style={{ height : 33, lineHeight :0 }}
                            loading={state?.savingSchoolDetails}
                            key="submit" htmlType="submit" form="ourSchoolForm" 
                            > Save Updates </Button>
                        }

                        <Button 
                            style={{ height : 33, lineHeight :0 }}
                            onClick={()=>{
                                setState({
                                    ...state, 
                                    updateSchoolDetails : !state?.updateSchoolDetails
                                })
                            }}
                            danger={state?.updateSchoolDetails}
                            type="primary"
                        >{ !state?.updateSchoolDetails? <><EditOutlined/> Edit </> : <CloseCircleOutlined/> } </Button>

                    </>
                }
                >

                    <div>
                        <span style={{ fontSize: 18, fontWeight : 600, marginBottom: "0.5rem" }}>Price Plan: </span> <span>USD {state?.school?.subscriptionAmount} {state?.school?.subscriptionType}</span>
                    </div>
                    <Form
                    disabled={!state?.updateSchoolDetails }
                    form = {form}
                    layout="vertical"
                    id = "ourSchoolForm"
                    onFinish={(v) => edit(v)}
                    >
                        <FormItem name={"name"} label="School Name">
                            <Input
                            name="name"
                            placeholder="School Name"
                            />
                        </FormItem>
                        <FormItem name={"address"} label="Address">
                            <Input name="address" placeholder="Address"/>
                        </FormItem>
                        <FormItem name={"motto"} label="School motto">
                            <Input name="motto" placeholder="School motto"/>
                        </FormItem>
                        <FormItem name={"website"} label="School Website">
                            <Input name="website" placeholder="e.g www.yourschool.com"/>
                        </FormItem>
                        <FormItem name={"email"} label="School Email">
                            <Input name="email" placeholder="School email"/>
                        </FormItem>
                        <FormItem name={'phone'} label="Phone Number">
                            <Input placeholder="School Phone" name="phone"/>
                        </FormItem>

                        <FormItem name={"type"} label="School Level">
                            <Select
                            showSearch
                            name="type"
                            onChange={(value)=>this.setState({ ...this.state, type : value })}
                            placeholder="School Level"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.children.includes(input)}
                            filterSort={(optionA, optionB) =>
                                (optionA.children || "").toLowerCase().localeCompare((optionB.children || "").toLowerCase())
                            }
                            >
                            {
                                [ 
                                { id : "primarySchool", name : "Primary School"},
                                { id : "secondarySchool", name : "Secondary School"}
                                ].map(item =>
                                <Option value={item.id} key={item.id}>{item.name}</Option>
                                )
                            }
                            </Select>
                        </FormItem>

                        <Form.Item>
                            {
                                (
                                    permissions.some(x=>x.permission?.name === "Create_Currency_Setting") ||
                                    permissions.some(x=>x.permission?.name === "Update_Currency_Setting")
                                ) &&
                                <Button onClick={()=>{
                                    setOpenCurrencyModal(true);
                                }} style={{ height : 33, lineHeight :0, marginRight : 8 }}
                                type=""
                                >
                                    Add Base Currency
                                </Button>
                            } School Reporting Currency : {state?.school?.baseCurrency?.name}
                        </Form.Item>
                    </Form>
                    

                </Card>

                <SchoolBaseCurrencyModal
                    setOpenModal = {setOpenCurrencyModal} 
                    show = {openCurrencyModal} 
                />

                </>
    )
} 


export{SchoolDetailsComponent}