

import { Button, Modal, Input, Form, Select, message, Col, Row, DatePicker,  } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { requestError } from '../../controllers/functions';
import DatePickerComponent from '../date/period';
import dayjs from "dayjs";

const { Option } = Select;
const { RangePicker } = DatePicker;
const SchoolPageTermModal = ({ show, setOpenModal, record, action, refresh }) => {

  const [form] = Form.useForm();
  
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [state, setState] = useState({});


  useEffect(() => {

    const data = {
        ...record, 
        period : [
            record?.startDate && dayjs(record?.startDate),
            record?.endDate && dayjs(record?.endDate),
        ],
        exitHolidayDates : [
            record?.exitHolidayStartDate && dayjs(record?.exitHolidayStartDate),
            record?.exitHolidayEndDate && dayjs(record?.exitHolidayEndDate),
        ]

    }
    setState(data);
    form.resetFields();
    form.setFieldsValue(data);
  }, 
  [show]);

  if(show !== isModalVisible){
    setIsModalVisible(show);
  }

  const submit = (event) =>{

    var object = {};

    if( (state?.period.length || 0 ) === 2){
        
        object = {
            ...state,
            startDate : state?.period[0]?.format("MM/DD/YYYY"),
            endDate : state?.period[1].format("MM/DD/YYYY"),
            exitHolidayStartDate : state?.exitHolidayDates && state?.exitHolidayDates[0]?.format("MM/DD/YYYY"),
            exitHolidayEndDate :  state?.exitHolidayDates &&  state?.exitHolidayDates[1]?.format("MM/DD/YYYY")
        }

        axios.post(process.env.REACT_APP_API_URI+"/school/terms", object , {headers: { authorization : `Bearer ${localStorage.getItem("token")}`}} ).then(res=>{
            message.success(res.data.res)
            setOpenModal(false);
            refresh();
          }).catch(error=>{
            requestError({error});
          }).finally(done=>{
              setLoading(false);
          })

    } else {
        setLoading(false);
        return message.error( "Select start and End Date!")
    }

    
  }

  const edit = (event) =>{

    
    var object = {};

    object = {
        ...state,
        startDate : state?.period[0].format("MM/DD/YYYY"),
        endDate : state?.period[1].format("MM/DD/YYYY"),
        exitHolidayStartDate : state?.exitHolidayDates && state?.exitHolidayDates[0]?.format("MM/DD/YYYY"),
        exitHolidayEndDate :  state?.exitHolidayDates &&  state?.exitHolidayDates[1]?.format("MM/DD/YYYY")
    }

    axios.put(process.env.REACT_APP_API_URI+`/school/terms/${state?.id}`, object , {headers: { authorization : `Bearer ${localStorage.getItem("token")}`}} ).then(res=>{
        message.success(res.data.res)
        setOpenModal(false);
        refresh();
      }).catch(error=>{
        requestError({error});
      }).finally(done=>{
          setLoading(false);
      })

  }

  const [loading, setLoading] = useState(false)

  

  return (
    <>
    
      <Modal 
      title={` SCHOOL TERM DETAILS ${action !== `create`?`FOR #${state?.id}`: ``}` }
      visible={isModalVisible} 
      onOk={()=>setOpenModal(false)} 
      onCancel={()=>setOpenModal(false)}
      footer={[
        <Button key="cancel" type=""  onClick={()=>{setOpenModal(false)}}>
            Cancel
        </Button>,
        <Button  key="submit" type="primary" htmlType="submit" form="schoolTermForm" loading={loading}>
          {action === `create`? `Submit` : `Update`}
        </Button>     
      ]}
      >
      <Form
      layout="vertical"
      className="row-col"
      onFinish={(e)=> {
        setLoading(true);
        if(action === "edit") {
          edit({isDraft : false});
        }else{
          submit({isDraft : false});
        }
      }}
      id="schoolTermForm" 
      form={form}
      >

        <Row>

          <Col xs={24} sm={24} md={10} lg={10} >
            <Form.Item
            name = "name" 
            label = "Select Term"
            style={{ marginRight: 5 }}
            rules={[
                { 
                  required: true, 
                  message: "Please input your  Term!" 
                },
            ]}>
              <Select
                showSearch
                onChange={(value)=>setState({ ...state, name : value })}
                value={state?.name}
                placeholder="Select Term"
                optionFilterProp="children"
                filterOption={(input, option) => option.children.includes(input)}
             
                >
                    {
                    [ 
                        { id : "Term One", name : "Term One"},
                        { id : "Term Two", name : "Term Two"},
                        { id : "Term Three", name : "Term Three"}
                    ].map(item =>
                    <Option value={item.id} key={item.id}>{item.name}</Option>
                    )
                    }
                </Select>
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={14} lg={14} >
                <Form.Item
                style={{marginRight: 5}}
                name={"period"}
                label="Term Opening and Closing"
                >
                    <RangePicker
                        style={{ width : "100%"}}
                        defaultValue={ state?.period || [] }
                        onChange={ (value) => {
                            setState({...state, period : value })
                        }} 
                    />
                </Form.Item>
            </Col>

        </Row>

        <Row>
            <Col xs={24} sm={24} md={14} lg={14} >
                <Form.Item
                
                style={{marginRight: 5}}
                name={"exitHolidayDates"}
                label="Exit Holiday"

                >
                    <RangePicker
                        style={{ width : "100%"}}
                        defaultValue={ state?.exitHolidayDates || [] }
                        onChange={ (value) => {
                            setState({...state, exitHolidayDates : value })
                        }} 
                    />
                </Form.Item>
            </Col>
        </Row>

        

        </Form>
      </Modal>
    </>
  );
};

export default SchoolPageTermModal;