import { useEffect, useState } from "react";
import { requestError } from "../../controllers/functions";
import axios from "axios"
import moment from "moment"
import { EditFilled } from "@ant-design/icons";
import SchoolPageTermModal from "../modals/school.page.terms";
import SchoolPageFeesModal from "../modals/school.page.fees";

const { List, Button, Form, Modal } = require("antd")


const SchoolFeesComponent = ({ permissions }) => {

    const [form] = Form.useForm();
    const [record, setRecord] = useState(null);
    const [openRecordModal, setOpenRecordModal] = useState(false);
    const [action, setAction] = useState('create');

    
    const [ state, setState ] = useState({ updateSchoolDetails : false });

    useEffect ( () => {
        refresh()
    }, [] ) 

    const refresh = () => {
        axios.get( process.env.REACT_APP_API_URI+"/school/fees", {headers: { authorization : `Bearer ${localStorage.getItem("token")}`}} ).then( done =>{
            setState({ ...state, terms : done.data });
            form.setFieldsValue(done.data);
        }).catch( error => {
            requestError({error})
        })
    }

    const deleteRecord = (id) => {
        axios.delete( process.env.REACT_APP_API_URI+`/school/fees/${id}`, {headers: { authorization : `Bearer ${localStorage.getItem("token")}`}} ).then( done =>{
            refresh();
        }).catch( error => {
            requestError({error});
        })
    }

    return ( 
        <>
            <List
                header={<div style={{ fontWeight:"bold" }}>School Fees</div>}

                dataSource={ state?.terms || [] }
                renderItem={(item) => (
                    <List.Item

                        actions={[
                            <div style={{ fontWeight : "bold"}}> {item?.currency?.name} {item?.amount}</div>,
                            <Button 
                            onClick={()=>{
                                Modal.confirm({
                                title  : "Are you want to delete this?",
                                onCancel : ()=>{},
                                onOk :  ()=> deleteRecord(item?.id)
                              })}} 
                              
                            style={{ height : 33, lineHeight :0 }} danger> Delete </Button>,
                            
                            <Button onClick={()=>{
                                setAction("edit");
                                setRecord(item);
                                setOpenRecordModal(true);
                            }} style={{ height : 33, lineHeight :0 }}> <EditFilled/> Edit </Button>
                        ]}

                    >
                        {item?.schoolTerm?.name} ({ moment(item?.date).format("YYYY")})
                    </List.Item>
                )}
            />

            <Button onClick={()=>{
                setOpenRecordModal(true);
                setRecord({});
                setAction("create");
            }} style={{ height : 33, lineHeight :0, float : "right", marginTop : "1rem" }}
            type="primary"
            >

                Add School Fees

            </Button>

            <SchoolPageFeesModal 
                refresh={refresh} 
                record={record} 
                action={action} 
                setOpenModal = {setOpenRecordModal} 
                show = {openRecordModal} 
            />

        </>
    )
}


export {SchoolFeesComponent}