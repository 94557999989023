import { Button, Card, Col, Modal, Row,  Table, message } from "antd"
import { SchoolDetailsComponent } from "../components/School.Page/school.details";
import { SchoolTermsComponent } from "../components/School.Page/school.terms";
import { SchoolFeesComponent } from "../components/School.Page/school.fees";
import { SchoolEventsComponent } from "../components/School.Page/school.events";
import { PublicHolidaysComponent } from "../components/School.Page/public.holidays";
import { EditTwoTone } from "@ant-design/icons";
import { requestError } from "../controllers/functions";
import axios from 'axios'
import { useEffect, useState } from "react";
import AccessLevelModal from "../components/modals/accessLevel";


const SchoolPage = ({permissions}) => {

    return (
        <Row >

            <Col className="school-page-widget" xs={24} sm={24} md={12} lg={8} >
                <SchoolDetailsComponent permissions = {permissions} />
            </Col>

            <Col className="school-page-widget"  xs={24} sm={24} md={12} lg={8} >
                <Card
                 size="small"
                 title={"School Terms and School Fees"}
                className="school-page-widget-card"
                >
                    <div >
                        <SchoolTermsComponent/>
                    </div>
                    
                    <div style={{paddingBottom : "2rem", paddingTop :"3rem"}}>
                        <SchoolFeesComponent/>
                    </div>

                </Card>
            </Col>

            <Col className="school-page-widget"  xs={24} sm={24} md={12} lg={8} >
              <Card
                 size="small"
                 title={"System Access Levels and Permissions"}
                className="school-page-widget-card"
                >
                    <AccessLevel permissions={ permissions }/>
                </Card>
  
            </Col>

            <Col className="school-page-widget"  xs={24} sm={24} md={12} lg={8} >
                <Card
                 size="small"
                 title={"Public Holidays"}
                className="school-page-widget-card"
                >
                    <PublicHolidaysComponent/>
                </Card>
  
            </Col>

            <Col className="school-page-widget"  xs={24} sm={24} md={12} lg={8} >
                

                <Card
                 size="small"
                 title={"School Events"}
                className="school-page-widget-card"
                >
                    
                    <SchoolEventsComponent/>

                </Card>
  
            </Col>

        </Row>
    )
}

export{SchoolPage}



const AccessLevel = ({ permissions }) => {

    const [accessLevels, setAccessLevels] = useState([]);
    const [openAccessLevelModal, setOpenAccessLevelModal] = useState(false);
    const [accessLevelId, setAccessLevelId] = useState(null);
    const [action, setAction] = useState(`create`);




    const columns = [
        {
          title: "NAME",
          dataIndex: "name",
          key: "name",
          width: "90%",
        },{
          title: "ACTION",
          key: "status",
          dataIndex: "status",
          width: "10%",
        }
      ];

      useEffect ( ()=> {
        refresh();
      }, [permissions])

      const refresh = () => {
        axios.get(process.env.REACT_APP_API_URI+"/auth/accesslevels", {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
            var arrayList = [];
            data.data.res.forEach(element => {
              arrayList.push(
                {
                  key: element?.id,
                  name: (
                    <>
                        <div className="avatar-info">
                          {element.name}
                        </div>
                    </>
                  ),
                  status: (
                    <>
                      
                      <Button 
                      onClick={()=>{
                        Modal.confirm({
                        title  : "Are you want to delete this?",
                        onCancel : ()=>{},
                        onOk :  ()=> deleteAccessLevel(element.id)
                      })}} 
                      
                      type="dashed" className="ant-btn-sm m-5 " danger>
                        Delete
                      </Button>
                      
                      <Button onClick={()=>{setAction(`edit`);setOpenAccessLevelModal(true); setAccessLevelId(element.id)}}  type="" className="ant-btn-sm m-5">
                        <EditTwoTone title="Edit Access Level" />
                      </Button>
                    </>
                  )
                }
              )
            });
            setAccessLevels(arrayList);
          }).catch(error =>{
            requestError({error});
          })
      }
    

      const deleteAccessLevel = (id) =>{
        axios.delete(process.env.REACT_APP_API_URI+`/auth/accesslevels/?id=${id}`, {headers: { authorization : `Beare ${localStorage.getItem("token")}`}}).then(data =>{
          message.success(data.data?.res );
          refresh();
        }).catch(error =>{
          console.log({error}) 
        })
      }

      
    return (<>
        <Table
            columns={columns}
            dataSource={accessLevels}
            pagination={false}
            className="ant-border-space"
        />

        <Button             onClick = {()=>{setOpenAccessLevelModal(true); setAction(`create`)}}  
            style={{ height : 33, lineHeight :0, float : "right", marginTop : "1rem" }}
            type="primary"
            >
                New Access Level
        </Button>

        <AccessLevelModal 
        accessLevelId={accessLevelId} 
        action={action} 
        setOpenAccessLevelModal = {setOpenAccessLevelModal} 
        show = {openAccessLevelModal}
        refresh = {refresh}
        />

    </>)
}